<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="fade-transition"
    offset-y="offset-y"
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{}">
      <v-text-field
        :color="color"
        :disabled="disabled"
        :filled="filled"
        :error-messages="errorMessages"
        :label="label"
        :prepend-icon="prependIconComputed"
        :type="type"
        @change="change"
        @click:prepend="show"
        @focus="$emit('focus', $event)"
        @keypress.enter="$refs.datetime.blur()"
        ref="datetime"
        v-model="datetime"
      ></v-text-field>
    </template>
    <v-date-picker
      color="accent"
      locale="pt-br"
      no-title="no-title"
      v-if="type === 'date'"
      v-model="datetime"
    >
      <v-spacer></v-spacer>
      <v-btn text="text" color="accent" @click="menu = false">Cancelar</v-btn>
      <v-btn text="text" color="accent" @click="change">Definir</v-btn>
    </v-date-picker>
    <v-time-picker
      color="accent"
      format="24hr"
      locale="pt-br"
      v-else
      v-model="datetime"
    >
      <v-spacer></v-spacer>
      <v-btn text="text" color="accent" @click="menu = false">Cancelar</v-btn>
      <v-btn text="text" color="accent" @click="change">Definir</v-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>
export default {
  name: "date-time-picker",
  props: [
    "color",
    "disabled",
    "error-messages",
    "filled",
    "label",
    "prependIcon",
    "value",
    "type",
  ],

  data: () => ({
    datetime: "",
    lastValue: "",
    menu: false,
  }),

  mounted() {
    if (this.type === "date") {
      this.datetime = this.$day(this.value).format("YYYY-MM-DD");
    } else {
      this.datetime = this.$day(this.value).format("HH:mm");
    }
  },

  watch: {
    menu(v) {
      if (!v && this.lastValue) {
        this.datetime = `${this.lastValue}`;
      }
    },

    value(v) {
      if (this.type === "date") {
        this.datetime = this.$day(v).format("YYYY-MM-DD");
      } else {
        this.datetime = this.$day(v).format("HH:mm");
      }
    },
  },

  computed: {
    prependIconComputed() {
      return this.$vuetify.breakpoint.lgAndUp ? this.prependIcon : undefined;
    },
  },

  methods: {
    change() {
      this.lastValue = null;
      this.menu = false;
      let val = "";

      if (this.type === "date") {
        val = `${this.datetime} ${this.$day(this.value || this.datetime).format(
          "HH:mm",
        )}`;
      } else {
        val = `${this.$day(this.value || this.datetime).format("YYYY-MM-DD")} ${
          this.datetime
        }`;
      }

      this.$emit("input", this.$day(val, "YYYY-MM-DD HH:mm").toJSON());
    },

    focus() {
      this.$refs.datetime.focus();
    },

    show() {
      this.menu = true;
      this.lastValue = `${this.datetime}`;
    },
  },
};
</script>
