<template>
  <base-container>
    <template v-slot:header>
      <v-btn
        class="mx-2"
        @click="$router.replace({ name: 'dashboard' })"
        color="accent"
        >Voltar</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn class="mx-2 green--text darken-1" @click="add('R')" color="white"
        >Receber</v-btn
      >
      <v-btn class="mr-2 red--text darken-1" @click="add('P')" color="white"
        >Pagar</v-btn
      >
    </template>
    <collection-data-table
      type="receivables-payables"
      @item-click="onItemPress"
    ></collection-data-table>
    <modal-receivables-payables ref="modal"></modal-receivables-payables>
  </base-container>
</template>

<script>
import BaseContainer from "@/components/BaseContainer.vue";
import CollectionDataTable from "@/components/CollectionDataTable";
import ModalReceivablesPayables from "@/components/ModalReceivablesPayables";

export default {
  name: "receivables-payables",
  components: { BaseContainer, CollectionDataTable, ModalReceivablesPayables },

  methods: {
    onItemPress({ id, type }) {
      this.$refs.modal.open(type, id);
    },

    add(t) {
      this.$refs.modal.open(t);
    },
  },
};
</script>
