<template>
  <v-row justify="center">
    <v-dialog
      :color="containerColor"
      :value="value"
      @input="$emit('input', $event)"
      fullscreen="fullscreen"
      hide-overlay="hide-overlay"
      persistent="persistent"
      transition="dialog-bottom-transition"
    >
      <v-card :disabled="loading" :loading="loading">
        <v-toolbar :class="{ 'mb-2': $vuetify.breakpoint.smAndUp }" flat="flat">
          <v-toolbar-items>
            <v-btn @click="$emit('input', false)" color="accent" text="text"
              >Fechar</v-btn
            >
          </v-toolbar-items>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <slot name="headerRight"></slot>
          </v-toolbar-items>
        </v-toolbar>
        <v-container
          class="modal-container"
          :class="{ 'elevation-2': $vuetify.breakpoint.smAndUp }"
        >
          <v-card-text>
            <slot></slot>
          </v-card-text>
          <v-card-actions>
            <slot name="footer"></slot>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "modal-container",
  props: ["loading", "title", "value"],

  mounted() {
    this.$events.$on("modal-container", this.handleEvent);
  },

  beforeDestroy() {
    this.$events.$off("modal-container", this.handleEvent);
  },

  computed: {
    containerColor() {
      return this.$vuetify.breakpoint.smAndUp ? "#fafafa" : "white";
    },
  },

  watch: {
    value(v) {
      this.$nextTick(() => this.$store.commit("modal-container", v));
    },
  },

  methods: {
    handleEvent(v) {
      if (!this.loading) {
        this.$emit("input", v);
      }
    },
  },
};
</script>

<style scoped>
.modal-container {
  max-width: 640px !important;
  background-color: white;
}
</style>
