<template>
  <modal-container :loading="loading" :title="titleComputed" v-model="dialog">
    <template v-slot:headerRight>
      <v-btn @click="save" color="accent" text="text">Salvar</v-btn>
    </template>
    <v-row>
      <v-col cols="12">
        <v-text-field
          :error-messages="error.value"
          @focus="error.value = null"
          @keypress.enter="$refs.createdAt.focus()"
          color="accent"
          label="Valor (R$)"
          placeholder="0,00"
          ref="value"
          type="tel"
          v-currency="currencyOptions"
          v-model="doc.value"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <date-time-picker
          :error-messages="error.createdAt"
          @focus="error.createdAt = null"
          color="accent"
          label="Data"
          ref="createdAt"
          type="date"
          v-model="doc.createdAt"
        ></date-time-picker>
      </v-col>
      <v-col cols="12">
        <v-text-field
          :error-messages="error.name"
          @focus="error.name = null"
          @keypress.enter="$refs.name.blur()"
          append-icon="mdi-pencil-circle-outline"
          color="accent"
          label="Descrição"
          ref="name"
          v-model="doc.name"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-menu offset-y="offset-y">
          <template v-slot:activator="{ on }">
            <v-chip
              class="mr-3 mb-3"
              :class="statusColor"
              link="link"
              v-on="on"
              title="Status"
            >
              <v-icon class="white--text" left="left">{{ statusIcon }}</v-icon
              ><span>{{ doc.status }}</span>
            </v-chip>
          </template>
          <v-list>
            <v-list-item
              @click="doc.status = s"
              v-for="s in statusItems"
              :key="s"
            >
              <v-list-item-title>{{ s }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-chip
          class="mr-3 mb-3"
          :class="doc.customer ? 'accent' : ''"
          @click="selectAccount"
          link="link"
          title="Cliente"
        >
          <v-icon left="left" :color="doc.customer ? 'white' : ''">{{
            customerIcon
          }}</v-icon
          ><span>{{ doc.customer ? doc.customer.name : "Cliente" }}</span>
        </v-chip>
        <v-chip
          class="mr-3 mb-3"
          v-if="endedAtComputed"
          title="Data do pagamento"
        >
          <v-icon left="left">mdi-calendar-check</v-icon
          ><span class="font-weight-medium">{{ endedAtComputed }}</span>
        </v-chip>
        <v-chip
          class="mr-3 mb-3"
          @click="navigateTo"
          v-if="doc.documentId"
          title="Ir para venda"
          ><span class="font-weight-medium">Venda</span>
          <v-icon right="right" small="small">mdi-open-in-new</v-icon>
        </v-chip>
      </v-col>
    </v-row>
    <template v-slot:footer v-if="docId">
      <v-spacer></v-spacer>
      <v-btn @click="remove" color="red" text="text">Excluir conta</v-btn>
      <v-spacer></v-spacer>
    </template>
    <collection-dialog
      :filters="accountsFilters"
      @data-selected="onSelectAccount"
      title="Selecione um cliente"
      type="accounts"
      v-if="collectionDialog"
      v-model="accountDialog"
    ></collection-dialog>
  </modal-container>
</template>

<script>
import CollectionDialog from "@/components/CollectionDialog.vue";
import DateTimePicker from "@/components/DateTimePicker.vue";
import ModalContainer from "@/components/ModalContainer.vue";

import { db, Timestamp } from "../plugins/google/firebase";
import { parseCurrency } from "vue-currency-input";
import $store from "@/store";
import firestore from "../plugins/firestore";

const defaultDoc = {
  createdAt: new Date().toJSON(),
  customer: null,
  endedAt: null,
  status: "Pendente",
  name: "",
  value: "",
  type: "P",
};

export default {
  name: "modal-receivables-payables",
  components: { CollectionDialog, DateTimePicker, ModalContainer },

  data: () => ({
    accountDialog: false,
    collectionDialog: false,
    dialog: false,
    loading: false,
    doc: Object.assign({}, defaultDoc),

    error: {
      createdAt: null,
      name: null,
      value: null,
    },

    docRef: null,
    docId: null,

    accountsFilters: {
      role: "Cliente",
    },
  }),

  computed: {
    endedAtComputed() {
      if (!this.doc.endedAt) {
        return "";
      }
      return this.$utils.calendar(this.doc.endedAt.toDate());
    },

    customerIcon() {
      return this.doc.customer ? "mdi-account-circle" : "mdi-account-plus";
    },

    currencyOptions() {
      return {
        autoDecimalMode: true,
        currency: null,
        precision: 2,
      };
    },

    statusColor() {
      switch (this.doc.status) {
        case "Pago":
          return "red accent-2 white--text";
        case "Recebido":
          return "green lighten-1 white--text";
        default:
          return "warning";
      }
    },

    statusIcon() {
      return this.doc.status !== "Pendente"
        ? "mdi-progress-check"
        : "mdi-progress-alert";
    },

    statusItems() {
      return this.doc.type === "P"
        ? ["Pendente", "Pago"]
        : ["Pendente", "Recebido"];
    },

    titleComputed() {
      return this.doc.type === "P" ? "Pagar" : "Receber";
    },

    typeColorComputed() {
      return this.doc.type === "P" ? "red" : "green";
    },
  },

  watch: {
    dialog(v) {
      if (!v) {
        this.$dialog.dismiss();
        setTimeout(() => {
          this.doc = Object.assign({}, defaultDoc);
          this.docRef = null;
          this.docId = null;
        }, 600);
      }
    },
  },

  methods: {
    async open(type, id) {
      defaultDoc.type = type;
      this.error = {
        createdAt: null,
        name: null,
        value: null,
      };

      if (id) {
        this.docRef = db
          .collection($store.getters.COLLECTION_RECEIVABLES_PAYABLES)
          .doc(id);
      } else {
        this.docRef = db
          .collection($store.getters.COLLECTION_RECEIVABLES_PAYABLES)
          .doc();
      }

      this.loading = !!id;
      this.dialog = true;

      if (id) {
        const snap = await this.docRef.get();
        if (snap.exists) {
          this.docId = snap.id;
          this.doc = {
            ...snap.data(),
            createdAt: this.$day(snap.get("createdAt").toDate()).toJSON(),
            value: this.$utils.decimal(snap.get("value"), 2),
          };
        }
      } else {
        this.doc = Object.assign({}, defaultDoc);
      }

      setTimeout(() => {
        if (this.loading) {
          this.loading = false;
        } else {
          this.$refs.value.focus();
        }
      }, 450);
    },

    validate() {
      if (!this.doc.value) {
        this.error.value = "Informe um valor";
      }
      if (!this.doc.name) {
        this.error.name = "Informe uma descrição";
      }
      if (!this.$day(this.doc.createdAt).isValid()) {
        this.error.createdAt = "Informe uma data válida";
      }

      if (Object.values(this.error).filter((v) => !!v).length) {
        return false;
      }

      return true;
    },

    async save() {
      try {
        if (!this.validate()) {
          return;
        }

        this.loading = true;

        const doc = Object.assign({}, this.doc);
        doc.value = parseCurrency(doc.value, this.currencyOptions);
        doc.createdAt = Timestamp.fromDate(new Date(doc.createdAt));

        await firestore.setReceivablesPayables(this.docRef, doc);

        this.$snackbar.show({ title: "Conta salva com sucesso" });
        this.dialog = false;
      } catch (e) {
        console.log(e);
        this.$snackbar.show({
          title: "Não foi possível salvar os dados, verifique a conexão",
        });
      } finally {
        this.loading = false;
      }
    },

    remove() {
      const actionRemove = async () => {
        try {
          this.loading = true;
          await firestore.deleteReceivablesPayables(this.docRef);

          this.$snackbar.show({ title: "Conta removida com sucesso" });
          this.dialog = false;
        } catch (e) {
          const title =
            e.msg || "Não foi possível remover esta conta, verifique a conexão";
          this.$snackbar.show({ title });
        } finally {
          this.loading = false;
        }
      };

      this.$dialog.show(
        "Remover conta",
        "Tem certeza que quer remover esta conta?",
        [
          { text: "cancelar" },
          { text: "remover", style: "destrutive", onClick: actionRemove },
        ],
      );
    },

    onSelectAccount(data) {
      if (data[0]) {
        this.doc.customer = { id: data[0].id, name: data[0].name };
      }
    },

    selectAccount() {
      if (this.doc.status !== "Pendente") {
        this.$snackbar.show({
          title: "Conta já liquidada, não é possível alterar cliente",
        });
        return;
      }
      if (!this.collectionDialog) {
        this.collectionDialog = true;
      }
      this.$nextTick(() => {
        setTimeout(() => {
          this.accountDialog = true;
        }, 250);
      });
    },

    navigateTo() {
      const onClick = () => {
        this.dialog = false;
        setTimeout(() => {
          this.$router.push({
            name: "document-edit",
            params: { type: "edit", docRef: this.doc.documentId },
          });
        }, 600);
      };

      this.$dialog.show(
        "Visualizar venda",
        "Deseja continuar e visualizar a venda correspondente a esta conta?",
        [
          { text: "cancelar" },
          { text: "visualizar", style: "destrutive", onClick },
        ],
      );
    },
  },
};
</script>
